import './VorgangToolbar.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { toDateTimeString } from '../../shared/dateTime';
import { GUTACHTENARTEN_TEXTE, FOTOKATEGORIEN } from '../../shared/domain/vorgang';
import {
  STATUS_TEXTE,
  AKTION_GUTACHTENART_AENDERN,
  AKTION_SACHVERSTAENDIGER_AENDERN,
  AKTION_STATUS_WECHSELN_VOR,
  AKTION_STATUS_WECHSELN_ZURUECK
} from '../../frontendConstants';
import { useSnackbar } from 'notistack';
import { SUCCESS_MESSAGE_AUTO_HIDE, ERROR_MESSAGE } from '../common/Alert';
import LoadingIndicator from '../common/LoadingIndicator';
import Status from '../common/Status';
import { dialogActions } from '../dialog/Dialog';
import { WarningDialog } from '../dialog/WarningDialog';
import VorgangInfoBar from './VorgangInfoBar';
import { Thumbnail } from '../common/Thumbnail';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { useUser } from '../../hooks/useUser';
import { isAenderbar } from '../../domain/isAenderbar';
import { holeGeschaedigten } from '../../shared/frontend/holeGeschaedigten';
import { deprecated_Benutzer, Vorgang } from '../../types';
import { aktionErlaubt } from '../../domain/aktionErlaubt';
import * as mutations from '../../graphql/mutations';
import { DatSynchronisierungButton } from './DatSynchronisierungButton';
import { Gutachtenart, Status as Gutachtenstatus } from '../../shared/constants';
import { naechsterStatus, vorherigerStatus } from '../../shared/statusHelper';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { getMessageFromError } from '../../shared/throw';

type BedingungenType = { [key: string]: boolean | BedingungenType };

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly speichereVorgang: (vorgang?: Partial<Vorgang>) => Promise<void>;
};

const gutachtenarten = Object.entries(GUTACHTENARTEN_TEXTE).map(([value, label]) => ({ label, value }));

export function VorgangToolbar(props: Props): JSX.Element | null {
  const el = document.querySelector('#layout__app-toolbar');
  return el ? ReactDOM.createPortal(<VorgangToolbarPortal {...props} />, el) : null;
}

function VorgangToolbarPortal({ vorgang, isLoading, setLoading, setzeVorgang, speichereVorgang }: Props): JSX.Element | null {
  const benutzerliste = useBenutzerliste(vorgang?.mandant ?? '');
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  let darfStatusWeiter = false;
  let darfStatusZurueck = false;

  if (vorgang?.status) {
    darfStatusWeiter = aktionErlaubt(AKTION_STATUS_WECHSELN_VOR, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);
    darfStatusZurueck = aktionErlaubt(AKTION_STATUS_WECHSELN_ZURUECK, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);
  }

  const darfGutachtenartAendern = aktionErlaubt(AKTION_GUTACHTENART_AENDERN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);
  const darfSachverstaendigenAendern = aktionErlaubt(AKTION_SACHVERSTAENDIGER_AENDERN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const handleStatusChange = async (vorgangId: string, status: string, bearbeiter?: string) => {
    try {
      const aktualisierterVorgang = await setzeStatus(vorgangId, status, bearbeiter === '__QUALITAETSSICHERUNG__' ? undefined : bearbeiter);
      setzeVorgang(aktualisierterVorgang);
      enqueueSnackbar(`Der Status wurde erfolgreich geändert.`, SUCCESS_MESSAGE_AUTO_HIDE);
      setStatusDialogOpen(false);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(getMessageFromError(error), ERROR_MESSAGE);
    }
  };

  const handleWeiseBearbeiterZu = async (event: SelectChangeEvent<string>) => {
    try {
      const neuerBearbeiter = event.target.value;
      const aktualisierterVorgang = await weiseBearbeiterZu(vorgang?.id || '', neuerBearbeiter);
      setzeVorgang(aktualisierterVorgang);
      enqueueSnackbar(
        `Der Vorgang wurde dem Sachbearbeiter ${neuerBearbeiter} zugewiesen und eine neue Vorgangsnummer wurde vergeben.`,
        SUCCESS_MESSAGE_AUTO_HIDE
      );
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(getMessageFromError(error), ERROR_MESSAGE);
    }
  };

  if (!vorgang?.vorgangsnummer) {
    return null;
  }

  return (
    <>
      <Toolbar className="vorgang-toolbar">
        <div className="vorgang-toolbar__titel" data-testid="vorgangHeadline">
          {vorgang?.fotos && vorgang?.fotos?.length > 0 && (
            <Hidden mdDown>
              <Thumbnail height={50} thumbnailkey={vorgang?.fotos[0]?.thumbnailkey ?? ''} />
            </Hidden>
          )}
          <div className="vorgang-toolbar__vorgangsnummer">{vorgang.vorgangsnummer}</div>
          <VorgangInfoBar vorgang={vorgang} />
          <Status
            dataTestId="status"
            className="vorgang-toolbar__status"
            onClick={() => setStatusDialogOpen(true)}
            disabled={!darfStatusWeiter && !darfStatusZurueck}
          >
            {STATUS_TEXTE[vorgang.status]}
          </Status>
          <FormControl variant="standard" className="vorgang-toolbar__auswahl">
            <InputLabel>Gutachtenart</InputLabel>
            <Select
              variant="standard"
              label="Gutachtenart"
              value={vorgang?.gutachtenart ?? gutachtenarten[0].value}
              disabled={!darfGutachtenartAendern || !isAenderbar(vorgang)}
              onChange={(event) => {
                speichereVorgang({
                  gutachtenart: event.target.value as Gutachtenart
                });
              }}
              data-testid="allgemeineDaten-gutachtenart"
            >
              {gutachtenarten.map((g) => (
                <MenuItem key={g.value} value={g.value} data-testid={g.label}>
                  {g.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" className="vorgang-toolbar__auswahl">
            <InputLabel>Sachverständiger</InputLabel>
            <Select
              variant="standard"
              label="Bearbeiter"
              value={benutzerliste.length > 0 && vorgang && vorgang.userId ? vorgang.userId : ''}
              disabled={!darfSachverstaendigenAendern || !isAenderbar(vorgang)}
              onChange={handleWeiseBearbeiterZu}
              {...(benutzerliste.length > 0 && vorgang && vorgang.userId ? { 'data-testid': 'allgemeineDaten-bearbeiter' } : {})}
            >
              {benutzerliste.length > 0 && vorgang && vorgang.userId
                ? benutzerliste.map((b) => (
                    <MenuItem key={b.id} value={b.id ?? undefined} data-testid={b.id}>
                      <Tooltip title={b.given_name && b.family_name ? `${b.given_name} ${b.family_name}` : (b.id ?? '')}>
                        <span>{b.kuerzel?.toUpperCase()}</span>
                      </Tooltip>
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          {vorgang?.gutachtenart === Gutachtenart.REPARATURNACHWEIS && vorgang.zugehoerigeGutachtenId && (
            <a
              href={`${window.location.origin}/vorgang/${vorgang.zugehoerigeGutachtenId}/gutachten`}
              className="vorgang-toolbar__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
              Zum Gutachten
            </a>
          )}
        </div>

        <div className="vorgang-toolbar__status__dat-sync">
          <Hidden mdDown>
            <span>
              {isLoading ? (
                'Vorgang wird aktualisiert...'
              ) : (
                <>
                  aktualisiert:&nbsp;
                  {toDateTimeString(vorgang?.updatedAt)}
                </>
              )}
            </span>
          </Hidden>

          <DatSynchronisierungButton
            vorgang={vorgang}
            isLoading={isLoading}
            setLoading={setLoading}
            speichereVorgang={speichereVorgang}
            setzeVorgang={setzeVorgang}
          />
        </div>

        {vorgang && (
          <StatusDialog
            open={statusDialogOpen}
            onClose={() => setStatusDialogOpen(false)}
            vorgang={vorgang}
            onStatusChange={handleStatusChange}
            isLoading={isLoading}
            darfStatusWeiter={darfStatusWeiter}
            darfStatusZurueck={darfStatusZurueck}
          />
        )}
      </Toolbar>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
}

type StatusDialogProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly vorgang: Vorgang;
  readonly onStatusChange: (vorgangId: string, status: string, bearbeiter?: string) => void;
  readonly isLoading: boolean;
  readonly darfStatusWeiter: boolean;
  readonly darfStatusZurueck: boolean;
};

function StatusDialog({ open, onClose, vorgang, onStatusChange, isLoading, darfStatusWeiter, darfStatusZurueck }: StatusDialogProps): JSX.Element {
  const [bedingungenIgnorierenDialogOpen, setBedingungenIgnorierenDialogOpen] = useState(false);
  const [qualitaetssicherungsbearbeiterDialogOpen, setQualitaetssicherungsbearbeiterDialogOpen] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [druckVersandHinweisOpen, setDruckVersandHinweisOpen] = useState(false);
  const [bedingungenFuerNaechstenStatus, setBedingungenFuerNaechstenStatus] = useState<BedingungenType>({});

  const statusReihenfolge = Object.values(Gutachtenstatus);

  useEffect(() => {
    const bedingungen = ermittleBedingungenFuerNaechstenStatus(vorgang);
    setBedingungenFuerNaechstenStatus(bedingungen);
  }, [vorgang]);

  const hatBedingungenFuerNaechstenStatus = Object.keys(bedingungenFuerNaechstenStatus).length > 0;

  const handleNaechsterStatus = () => {
    if (nichtAlleBedingungenErfuellt(bedingungenFuerNaechstenStatus)) {
      setBedingungenIgnorierenDialogOpen(true);
    } else if (vorgang.status === Gutachtenstatus.GUTACHTENAUSARBEITUNG) {
      setQualitaetssicherungsbearbeiterDialogOpen(true);
    } else if (vorgang.status === Gutachtenstatus.QUALITAETSSICHERUNG) {
      setDruckVersandHinweisOpen(true);
    } else if (vorgang.status === Gutachtenstatus.BESICHTIGUNG) {
      onStatusChange(vorgang.id, Gutachtenstatus.GUTACHTENAUSARBEITUNG);
    } else {
      onStatusChange(vorgang.id, naechsterStatus(vorgang.status));
    }
  };

  const nichtAllePflichtBedingungenErfuellt = () => {
    return vorgang.status === Gutachtenstatus.GUTACHTENAUSARBEITUNG && !vorgang.verwaltungscheckbox;
  };

  const handleNaechsterStatusOhneBedingungen = () => {
    setBedingungenIgnorierenDialogOpen(false);
    if (vorgang.status === Gutachtenstatus.BESICHTIGUNG) {
      onStatusChange(vorgang.id, Gutachtenstatus.GUTACHTENAUSARBEITUNG);
    } else {
      onStatusChange(vorgang.id, naechsterStatus(vorgang.status));
    }
  };

  const handleWechsleZuQualitaetssicherung = (bearbeiter: string) => {
    setQualitaetssicherungsbearbeiterDialogOpen(false);
    onStatusChange(vorgang.id, naechsterStatus(vorgang.status), bearbeiter);
  };

  const handleVorherigerStatus = () => {
    onStatusChange(vorgang.id, vorherigerStatus(vorgang.status));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'md'} aria-labelledby="statusDialogTitle" data-testid="statusDialog">
        <DialogTitle id="statusDialogTitle">
          <Typography variant="h6">Status</Typography>
          <IconButton aria-label="close" className="vorgang-toolbar__dialog" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={statusReihenfolge.indexOf(vorgang.status)} orientation="vertical">
            {statusReihenfolge.map((status) => (
              <Step key={status}>
                <StepLabel>{(STATUS_TEXTE as Record<string, string>)[status]}</StepLabel>
                <StepContent>
                  <div>
                    <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                      {hatBedingungenFuerNaechstenStatus && (
                        <Grid item xs={12}>
                          <BedingungenFuerNaechstenStatus bedingungenFuerNaechstenStatus={bedingungenFuerNaechstenStatus} />
                        </Grid>
                      )}
                      {darfStatusZurueck && (
                        <Grid item xs={12} sm={6}>
                          <Button
                            disabled={isLoading}
                            onClick={handleVorherigerStatus}
                            variant="contained"
                            color="secondary"
                            data-testid={(STATUS_TEXTE as Record<string, string>)[vorherigerStatus(vorgang.status)]}
                          >
                            Zurück
                          </Button>
                        </Grid>
                      )}
                      {darfStatusWeiter && (
                        <>
                          <Grid item xs={3}>
                            <Button
                              disabled={isLoading || nichtAllePflichtBedingungenErfuellt()}
                              variant="contained"
                              color="primary"
                              onClick={handleNaechsterStatus}
                              data-testid={(STATUS_TEXTE as Record<string, string>)[naechsterStatus(vorgang.status)]}
                              data-nicht-alle-bedingungen-erfuellt={nichtAlleBedingungenErfuellt(bedingungenFuerNaechstenStatus)}
                              startIcon={nichtAlleBedingungenErfuellt(bedingungenFuerNaechstenStatus) && <WarningIcon />}
                            >
                              Weiter
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            {vorgang?.status === Gutachtenstatus.BESICHTIGUNG && (
                              <Button
                                disabled={isLoading}
                                variant="contained"
                                color="primary"
                                onClick={() => onStatusChange(vorgang.id, Gutachtenstatus.BEWEISSICHERUNG)}
                                data-testid={Gutachtenstatus.BEWEISSICHERUNG}
                              >
                                Beweissicherung
                              </Button>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      </Dialog>
      <BedingungenIgnorierenDialog
        open={bedingungenIgnorierenDialogOpen}
        onClose={() => setBedingungenIgnorierenDialogOpen(false)}
        onNaechsterStatus={handleNaechsterStatusOhneBedingungen}
      />
      <QualitaetssicherungsbearbeiterDialog
        open={qualitaetssicherungsbearbeiterDialogOpen}
        onClose={() => setQualitaetssicherungsbearbeiterDialogOpen(false)}
        onWechsleZuQualitaetssicherung={handleWechsleZuQualitaetssicherung}
      />
      {warningDialogOpen && (
        <WarningDialog
          open={warningDialogOpen}
          onAbort={() => {
            setWarningDialogOpen(false);
            setQualitaetssicherungsbearbeiterDialogOpen(false);
            onClose();
          }}
          text={'Es fehlen noch wichtige Daten zum Auftraggeber (Geburtsdatum, E-Mail, Telefonnummer). Bitte fügen Sie die Daten hinzu.'}
          title={'Fehlende Daten'}
          onChooseYes={() => {
            setWarningDialogOpen(false);
          }}
          confirmText={'Weiter'}
          abortText={'Abbrechen'}
        />
      )}
      {druckVersandHinweisOpen && (
        <WarningDialog
          open={druckVersandHinweisOpen}
          onAbort={() => {
            setDruckVersandHinweisOpen(false);
            onClose();
          }}
          text={'Änderung des Status zurück in den vorherigen Status ist nicht mehr möglich'}
          title={'Hinweis'}
          onChooseYes={() => {
            handleNaechsterStatusOhneBedingungen();
            setDruckVersandHinweisOpen(false);
          }}
          confirmText={'Weiter'}
          abortText={'Abbrechen'}
        />
      )}
    </>
  );
}

type BedingungenFuerNaechstenStatusProps = {
  readonly bedingungenFuerNaechstenStatus: BedingungenType;
};

function BedingungenFuerNaechstenStatus({ bedingungenFuerNaechstenStatus }: BedingungenFuerNaechstenStatusProps) {
  const [openBedingungen, setOpenBedingungen] = useState<BedingungenType>({});

  const createToggleListItem = (feld: string) => () => setOpenBedingungen({ ...openBedingungen, [feld]: !openBedingungen[feld] });

  const wrapWithList = (children: React.ReactNode, uebergeordnetesFeld?: string) =>
    uebergeordnetesFeld ? (
      <Collapse in={openBedingungen[uebergeordnetesFeld] as boolean} timeout="auto" unmountOnExit>
        <List dense disablePadding component="div">
          {children}
        </List>
      </Collapse>
    ) : (
      <List dense>{children}</List>
    );

  type BedingungenProps = { bedingungen: BedingungenType; uebergeordnetesFeld?: string };

  const Bedingungen = ({ bedingungen, uebergeordnetesFeld }: BedingungenProps) =>
    wrapWithList(
      Object.keys(bedingungen).map((feld) => {
        const hatUnterbedingungen = typeof bedingungen[feld] === 'object';
        const alleUnterbedingungenErfuellt =
          bedingungen[feld] && Object.keys(bedingungen[feld]).every((unterfeld) => (bedingungen[feld] as BedingungenType)[unterfeld]);
        const bedingungErfuellt = hatUnterbedingungen ? alleUnterbedingungenErfuellt : bedingungen[feld];

        return (
          <React.Fragment key={feld}>
            <ListItem onClick={createToggleListItem(feld)}>
              <ListItemIcon>{bedingungErfuellt ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}</ListItemIcon>

              <ListItemText>{feldZuLabel(feld)}</ListItemText>
              {hatUnterbedingungen && (openBedingungen[feld] ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </ListItem>

            {hatUnterbedingungen && <Bedingungen bedingungen={bedingungen[feld] as BedingungenType} uebergeordnetesFeld={feld} />}
          </React.Fragment>
        );
      }),
      uebergeordnetesFeld
    );

  return <Bedingungen bedingungen={bedingungenFuerNaechstenStatus} />;
}

type BedingungenIgnorierenDialogProps = {
  readonly open: boolean;
  readonly onNaechsterStatus: () => void;
  readonly onClose: () => void;
};

function BedingungenIgnorierenDialog({ open, onNaechsterStatus, onClose }: BedingungenIgnorierenDialogProps): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="bedingungenIgnorierenDialogTitle" aria-describedby="bedingungenIgnorierenDialogBeschreibung">
      <DialogTitle id="bedingungenIgnorierenDialogTitle">Nicht alle Bedingungen erfüllt</DialogTitle>
      <DialogContent>
        <DialogContentText id="bedingungenIgnorierenDialogBeschreibung">
          Für den Statuswechsel sind noch nicht alle Bedingungen erfüllt. Wollen Sie die Bedingungen ignorieren und den neuen Status setzen?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button onClick={onNaechsterStatus} variant="contained" color="primary" data-testid="statuswechselOhneBedingungen">
          Status wechseln
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function useBenutzerlisteInGruppe(gruppe: string) {
  const [benutzerliste, setBenutzerliste] = useState<deprecated_Benutzer[]>([]);
  useEffect(() => {
    let didCancel = false;
    async function holeBenutzerlisteInGruppe() {
      const bl = await makeGraphqlQuery(queries.holeBenutzerlisteInGruppe, {
        gruppe
      });
      if (!didCancel) {
        setBenutzerliste(bl);
      }
    }
    holeBenutzerlisteInGruppe();
    return () => {
      didCancel = true;
    };
  }, [gruppe]);
  return benutzerliste;
}

type QualitaetssicherungsbearbeiterDialogProps = {
  readonly open: boolean;
  readonly onWechsleZuQualitaetssicherung: (bearbeiter: string) => void;
  readonly onClose: () => void;
};

function QualitaetssicherungsbearbeiterDialog({ open, onWechsleZuQualitaetssicherung, onClose }: QualitaetssicherungsbearbeiterDialogProps): JSX.Element {
  const [userId, setUserId] = useState('__QUALITAETSSICHERUNG__');

  const benutzerliste = useBenutzerlisteInGruppe('Qualitaetssicherung');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="qualitaetssicherungsbearbeiterDialogTitle"
      aria-describedby="qualitaetssicherungsbearbeiterDialogBeschreibung"
      data-testid="qualitaetssicherungsbearbeiterDialog"
    >
      <DialogTitle id="qualitaetssicherungsbearbeiterDialogTitle">Bearbeiter für Qualitätssicherung auswählen</DialogTitle>
      <DialogContent>
        <DialogContentText id="qualitaetssicherungsbearbeiterDialogBeschreibung">
          Bitte wählen Sie einen Bearbeiter, der die Qualitätssicherung durchführen soll.
        </DialogContentText>
        <FormControl variant="standard" key={benutzerliste.length} fullWidth>
          <InputLabel>Bearbeiter</InputLabel>
          <Select
            variant="standard"
            label="Bearbeiter"
            value={userId}
            onChange={(event) => setUserId(event.target.value)}
            {...(benutzerliste.length > 0 ? { 'data-testid': 'qualitaetssicherungsbearbeiter' } : {})}
          >
            <MenuItem value="__QUALITAETSSICHERUNG__" data-testid="qualitaetssicherung">
              Qualitätssicherung
            </MenuItem>
            {benutzerliste.length > 0
              ? benutzerliste.map((b) => (
                  <MenuItem key={b.id} value={b.id ?? undefined} data-testid={b.id}>
                    {b.given_name && b.family_name ? `${b.given_name} ${b.family_name}` : b.id} ({b.kuerzel?.toUpperCase()})
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={!userId}
          onClick={() => onWechsleZuQualitaetssicherung(userId)}
          variant="contained"
          color="primary"
          data-testid="statuswechselMitQualitaetssicherungsbearbeiter"
        >
          Status wechseln
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const FELDTEXTE: Record<string, string> = {
  vorsteuerabzugsberechtigt: 'Fahrzeug im Betriebsvermögen',
  rechtsschutzVorhanden: 'Rechtschutzversicherung',
  profiltiefe: 'Profiltiefe',
  anzahlVorbesitzer: 'Anzahl der Vorbesitzer',
  scheckheftgepflegt: 'Scheckheftgepflegt',
  unterschrift: 'Unterschriften',
  fotos: 'Fotos',
  abtretung: 'Abtretung',
  his: 'HIS',
  dsgvo: 'DSGVO'
};

function feldZuLabel(feld: string) {
  return FELDTEXTE[feld] ? FELDTEXTE[feld] : feld;
}

function useBenutzerliste(mandant: string) {
  const [benutzerliste, setBenutzerliste] = useState<deprecated_Benutzer[]>([]);

  useEffect(() => {
    let didCancel = false;
    async function holeBenutzerliste() {
      const bl = await makeGraphqlQuery(queries.holeBenutzerliste, { mandant });
      if (!didCancel) {
        setBenutzerliste(bl);
      }
    }
    if (mandant) {
      holeBenutzerliste();
    }
    return () => {
      didCancel = true;
    };
  }, [mandant]);

  return benutzerliste;
}

function hatFotoMitKategorie(vorgang: Vorgang, kategorie: string) {
  return (vorgang.fotos ?? []).some((foto) => foto.kategorien?.includes(kategorie));
}

export function nichtAlleBedingungenErfuellt(bedingungen: BedingungenType): boolean {
  return Object.keys(bedingungen).some((feld) =>
    //@ts-ignore
    typeof bedingungen[feld] === 'object' ? nichtAlleBedingungenErfuellt(bedingungen[feld]) : !bedingungen[feld]
  );
}

export function ermittleBedingungenFuerNaechstenStatus(vorgang: Vorgang): BedingungenType {
  if (vorgang.status === Gutachtenstatus.BESICHTIGUNG) {
    return {
      vorsteuerabzugsberechtigt: wertGesetzt(vorgang.auftraggeberVorsteuerabzugsberechtigt),
      rechtsschutzVorhanden: wertGesetzt(vorgang.auftraggeberRechtsschutzVorhanden),
      anzahlVorbesitzer: wertGesetzt(vorgang.fahrzeugdaten?.anzahlVorhalter),
      scheckheftgepflegt: wertGesetzt(vorgang.fahrzeugdaten?.scheckheftgepflegt),
      unterschrift: {
        abtretung: Boolean(vorgang.unterschriften?.abtretungserklaerung && vorgang.unterschriften?.abtretungserklaerung?.key),
        his: Boolean(vorgang.unterschriften?.his && vorgang.unterschriften?.his?.key),
        dsgvo: Boolean(vorgang.unterschriften?.dsgvo && vorgang.unterschriften?.dsgvo?.key)
      },
      fotos: FOTOKATEGORIEN.reduce((fotos: BedingungenType, kategorie: string) => {
        fotos[kategorie] = hatFotoMitKategorie(vorgang, kategorie);
        return fotos;
      }, {})
    };
  } else if (vorgang.status === Gutachtenstatus.GUTACHTENAUSARBEITUNG) {
    const bedingungen: Record<string, boolean> = {
      Verwaltung: vorgang.verwaltungscheckbox ?? false
    };

    const geschaedigter = holeGeschaedigten(vorgang);
    if (geschaedigter) {
      bedingungen['Email Auftraggeber'] = Boolean(geschaedigter?.email && geschaedigter?.email[0]);
      bedingungen['Geburtsdatum Auftraggeber'] = Boolean(geschaedigter.geburtsdatum);
    }
    return bedingungen;
  } else if (vorgang.status === Gutachtenstatus.DRUCK_VERSAND) {
    return { 'Gutachten versandt': Boolean(vorgang.wurdeGutachtenVersandt) };
  }

  return {};
}

export function wertGesetzt(wert: string | boolean | null | undefined) {
  return typeof wert !== 'undefined' && wert !== null;
}

export async function weiseBearbeiterZu(vorgangId: string, neuerBearbeiter: string): Promise<Vorgang> {
  return makeGraphqlQuery(mutations.weiseBearbeiterZu, {
    vorgangId,
    neuerBearbeiter
  });
}

export async function setzeStatus(vorgangId: string, status: string, bearbeiter?: string): Promise<Vorgang> {
  return makeGraphqlQuery(mutations.setzeStatus, {
    vorgangId,
    status,
    bearbeiter
  });
}
