import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GavelIcon from '@mui/icons-material/Gavel';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import { toDateTimeString } from '../../shared/dateTime';
import { useDateiUrl } from '../../hooks/useDateiUrl';
import { Event, PaginierteListe, Vorgang } from '../../types';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { EVENT_TYPE_ZAHLUNG_GELOESCHT, EVENT_TYPE_ZAHLUNG_IMPORTIERT } from '../../shared/constants';

const EVENT_TYPE_VORGANG_ANGELEGT = 'VORGANG_ANGELEGT';
const EVENT_TYPE_VORGANG_AKTUALISIERT = 'VORGANG_AKTUALISIERT';
const EVENT_TYPE_STATUS_GEWECHSELT = 'STATUS_GEWECHSELT';
const EVENT_TYPE_BEARBEITER_ZUGEWIESEN = 'BEARBEITER_ZUGEWIESEN';
const EVENT_TYPE_DOSSIER_ANGELEGT = 'DOSSIER_ANGELEGT';
const EVENT_TYPE_DOSSIER_AKTUALISIERT = 'DOSSIER_AKTUALISIERT';
const EVENT_TYPE_ANGEBOTSANFRAGE_VERSENDET = 'ANGEBOTSANFRAGE_VERSENDET';
const EVENT_TYPE_HIS_VERSENDET = 'HIS_VERSENDET';
const EVENT_TYPE_UNTERSCHRIFT_GELEISTET = 'UNTERSCHRIFT_GELEISTET';
const EVENT_TYPE_UNTERSCHRIFT_ENTFERNT = 'UNTERSCHRIFT_ENTFERNT';
const EVENT_TYPE_GUTACHTEN_GENERIERT = 'GUTACHTEN_GENERIERT';
const EVENT_TYPE_GUTACHTEN_VERSENDET = 'GUTACHTEN_VERSENDET';

const EVENT_TYPE_BESICHTIGUNG_ANGELEGT = 'BESICHTIGUNG_ANGELEGT';
const EVENT_TYPE_BESICHTIGUNG_AKTUALISIERT = 'BESICHTIGUNG_AKTUALISIERT';
const EVENT_TYPE_BESICHTIGUNG_ENTFERNT = 'BESICHTIGUNG_ENTFERNT';

const EVENT_TEXTE: Record<string, string> = {
  [EVENT_TYPE_VORGANG_ANGELEGT]: 'Vorgang angelegt',
  [EVENT_TYPE_VORGANG_AKTUALISIERT]: 'Vorgang aktualisiert',
  [EVENT_TYPE_BESICHTIGUNG_AKTUALISIERT]: 'Besichtigung aktualisiert',
  [EVENT_TYPE_DOSSIER_ANGELEGT]: 'Dossier in SilverDAT angelegt',
  [EVENT_TYPE_DOSSIER_AKTUALISIERT]: 'Dossier in SilverDAT aktualisiert',
  [EVENT_TYPE_GUTACHTEN_GENERIERT]: 'Gutachten generiert',
  [EVENT_TYPE_BEARBEITER_ZUGEWIESEN]: 'Bearbeiter zugewiesen',
  [EVENT_TYPE_GUTACHTEN_VERSENDET]: 'Gutachten versendet',
  [EVENT_TYPE_HIS_VERSENDET]: 'HIS versendet',
  [EVENT_TYPE_STATUS_GEWECHSELT]: 'Status gewechselt',
  [EVENT_TYPE_UNTERSCHRIFT_GELEISTET]: 'Unterschrift geleistet',
  [EVENT_TYPE_UNTERSCHRIFT_ENTFERNT]: 'Unterschrift entfernt',
  [EVENT_TYPE_ANGEBOTSANFRAGE_VERSENDET]: 'Angebotsanfrage versendet',
  [EVENT_TYPE_BESICHTIGUNG_ANGELEGT]: 'Besichtigung angelegt',
  [EVENT_TYPE_BESICHTIGUNG_ENTFERNT]: 'Besichtigung entfernt',
  [EVENT_TYPE_ZAHLUNG_IMPORTIERT]: 'Zahlungseingang importiert',
  [EVENT_TYPE_ZAHLUNG_GELOESCHT]: 'Zahlungseingang gelöscht'
};

const FELDBEZEICHNUNGEN: Record<string, string> = {
  besichtigungsdaten: 'Daten zur Besichtigung',
  auftraggeber: 'Auftraggeber',
  fahrzeughalter: 'Fahrzeughalter',
  fahrzeugdaten: 'Fahrzeugdaten',
  altschaeden: 'Altschäden',
  schaeden: 'Schäden',
  bauteil: 'Bauteil',
  reparierteVorschaeden: 'reparierte Vorschäden',
  vorschaeden: 'Vorschäden',
  sachFachgerecht: 'sach und Fachgerecht',
  reparaturart: 'Reparaturart',
  lackschichtendickenmessung: 'Lackschichtendickenmessung',
  beschaedigung: 'Beschädigung',
  bewertung: 'Bewertung',
  stundenverrechnungssaetze: 'Stundenverrechnungssätze',
  unfallgegner: 'Unfallgegner',
  unfalldaten: 'Unfalldaten',
  reparaturdauer: 'Reparaturdauer',
  dateien: 'Dateien',
  gutachten: 'Gutachten',
  aufgaben: 'Aufgaben',
  bereifung: 'Bereifung',
  reifen: 'Reifen',
  kategorien: 'Kategorien',
  vorgangsnummer: 'Vorgangsnummer',
  status: 'Status',
  gruppen: 'Gruppen',
  ehemaligeBearbeiter: 'Ehemalige Bearbeiter',
  gutachtenart: 'Gutachtenart',
  strasse: 'Straße',
  plz: 'PLZ',
  ort: 'Ort',
  vermittler: 'Vermittler',
  besichtigungstermin: 'Besichtigungstermin',
  anrede: 'Anrede',
  firmenname: 'Firmenname',
  nachname: 'Nachname',
  vorname: 'Vorname',
  telefon: 'Telefon',
  geburtsdatum: 'Geburtsdatum',
  nationalitaet: 'Nationalität',
  email: 'E-Mail',
  rechtsschutzVorhanden: 'Rechtsschutz vorhanden',
  vorsteuerabzugsberechtigt: 'Vorsteuerabzugsberechtigt',
  istAuftraggeber: 'ist Auftraggeber',
  fahrgestellnummer: 'Fahrgestellnummer',
  kilometerleistung: 'Kilometerleistung',
  land: 'Land',
  kennzeichen: 'Kennzeichen',
  erstesZulassungsdatum: '1. Zulassungsdatum',
  letztesZulassungsdatum: 'Letztes Zulassungsdatum',
  naechsteHauptuntersuchung: 'Nächste HU',
  anzahlVorhalter: 'Anzahl der Vorhalter',
  scheckheftgepflegt: 'Scheckheftgepflegt',
  zustand: 'Zustand',
  position: 'Position',
  profiltiefe: 'Profiltiefe',
  wertverbesserung: 'Wertverbesserung',
  restwert: 'Restwert',
  angebote: 'Angebote',
  mechanik: 'Mechanik',
  karosserie: 'Karosserie',
  lackierung: 'Lackierung',
  unfallort: 'Unfallort',
  datum: 'Datum',
  fahrzeughersteller: 'fahrzeughersteller',
  fahrzeugart: 'Fahrzeugart',
  sonstigeFahrzeugart: 'sonstige Fahrzeugart',
  versicherung: 'Versicherung',
  versicherungsnummer: 'Versicherungsnummer',
  schadenart: 'Schadenart',
  schadennummer: 'Schadennummer',
  arbeitswerteProStunde: 'Arbeitswerte/Std.',
  materialbeschaffungsdauer: 'Materialbeschaffungsdauer',
  gutachtennummer: 'Gutachtennummer',
  key: 'Dateischlüssel',
  dateiname: 'Dateiname',
  erstellungsdatum: 'Erstellungsdatum',
  typ: 'Typ',
  uploaddatum: 'Uploaddatum',
  wert: 'Angebotswert',
  finaleDatenerfassung: 'Finale Datenerfassung',
  bildbearbeitung: 'Bildbearbeitung',
  kalkulationserstellung: 'Kalkulationserstellung',
  wiederbeschaffungswertUndSchadensumfang: 'Wiederbeschaffungswert und Schadensumfang',
  merkantilerMinderwert: 'merkantiler Minderwert',
  textbearbeitung: 'Textbearbeitung',
  auftraggeberRechtsschutzVorhanden: 'Rechtsschutz vorhanden (Auftraggeber)',
  fahrzeughalterRechtsschutzVorhanden: 'Rechtsschutz vorhanden (Fahrzeughalter)',
  auftraggeberVorsteuerabzugsberechtigt: 'Vorsteuerabzugsberechtigt',
  factoring: 'Factoring',
  plausibilitaet: 'Plausibilität',
  bemerkungenZumSchaden: 'Schadenhergang',
  schadenhergang: 'Beschreibung',
  finanzierungMoeglich: 'Finanzierung möglich (Büro)',
  rechtsanwalt: 'Rechtsanwalt',
  werkstatt: 'Werkstatt',
  his: 'HIS',
  unterzeichner: 'Unterzeichner',
  dsgvo: 'DSGVO',
  unterschriften: '',
  abtretungserklaerung: 'Abtretungserklärung',
  zulassungsbescheinigung: 'Zulassungsbescheinigung',
  kilometerleistungGeschaetzt: 'Kilometerleistung geschätzt',
  kilometerleistungAngegeben: 'Kilometerleistung angegeben',
  fahrzeughalterIstAuftraggeber: 'Fahrzeughalter ist Auftraggeber',
  container: 'DAT Marktindex Code',
  containerName: 'DAT Marktindex',
  fahrzeugartname: 'Fahrzeugart',
  leistungKw: 'Leistung in KW',
  farbe: 'Farbe',
  haupttypname: 'Haupttyp',
  untertypname: 'Untertyp',
  herstellername: 'Hersteller',
  anzahlSitze: 'Anzahl Sitze',
  antriebsart: 'Antriebsart',
  aufbauart: 'Aufbauart',
  serienausstattung: 'Serienausstattung',
  nutzungsausfallKlasseOhneFahrzeugAlter: 'Nutzungsausfallklasse ohne Fahrzeugalter',
  nutzungsausfallKlasse: 'Nutzungsausfallklasse',
  phantomkalkulation: 'DAT Phantomkalkulation',
  hubraum: 'Hubraum',
  mietwagenklasse: 'Mietwagenklasse',
  anzahlTueren: 'Anzahl Türen',
  sonderausstattung: 'Sonderausstattung',
  aufschlagLackmaterial: 'Aufschlag Lackmaterial',
  elektrik: 'Elektrik',
  aufschlagErsatzteil: 'Aufschlag Ersatzteil',
  lackart: 'Lackart',
  verbringungszeit: 'Verbringungszeit',
  inklusiveMaterial: 'inklusive Material',
  lacquerMethod: 'DAT-Lacksystem',
  achsen: 'Achse',
  hersteller: 'Hersteller',
  achsenPosition: 'Achsenposition',
  reifengroesse: 'Reifengröße',
  altschaden: 'Altschaden',
  schadenVorhandenAuswahl: 'Schadenvorhanden Auswahl',
  allgemeinzustand: 'Allgemeinzustand',
  bemerkungen: 'Bemerkungen',
  fotos: 'Fotos',
  beschreibung: 'Beschreibung',
  karosserievermessungErforderlich: 'Karosserievermessung erforderlich',
  beilackierungNotwendig: 'Beilackierung notwendig',
  achsvermessungDurchgefuehrt: 'Achsvermessung durchgeführt',
  anhaengerkupplungAusSicherheitsgruendenErneuern: 'Anhängerkupplung aus Sicherheitsgründen erneuern',
  probefahrtErforderlich: 'Probefahrt erforderlich',
  pruefarbeitenErforderlich: 'Prüfarbeiten erforderlich',
  fehlerspeicherAuslesen: 'Fehlerspeicher auslesen',
  lenkgetriebeErneuer: 'Lenkgetriebe erneuern',
  richtbankErforderlich: 'Richtbank erforderlich',
  verkehrssicherNachNotreparatur: 'Verkehrssicher nach Notreparatur',
  achsvermessungMitSchadenfeststellung: 'Achsvermessung mit Schadenfeststellung',
  fahrbereit: 'Fahrbereit',
  achsweiseErneuerungDerReifen: 'Achsweise Erneuerung der Reifen',
  verkehrssicher: 'Verkehrssicher',
  achsweiseErneuerungDerStossdaempfer: 'Achsweise Erneuerung der Stossdaempfer',
  achsvermessungErforderlich: 'Achsvermessung erforderlich',
  lenkgetriebeErneuern: 'Lenkgetriebe erneuern',
  reparierteSchadenbereiche: 'Reparierte Schadenbereiche',
  baugruppe: 'Baugruppe',
  arbeitsPositionen: 'Arbeitspositionen',
  preisProEinheit: 'Preis pro Einheit',
  lackstufe: 'Lackstufe',
  schlussbemerkung: 'Schlussbemerkung',
  reparaturkosten: 'Reparaturkosten',
  korrekturWiederbeschaffungswert: 'Korrektur Wiederbeschaffungswert',
  wiederbeschaffungswert: 'Wiederbeschaffungswert',
  bemerkung: 'Bemerkung',
  veraeusserungswert: 'Veräusserungswert',
  neupreis: 'Neupreis',
  materialkosten: 'Materialkosten',
  fahrzeugalter: 'Fahrzeugalter',
  lohnkosten: 'Lohnkosten',
  ueberschrift: 'Überschrift',
  disabled: 'Deaktiviert',
  text: 'Text',
  vorgangTextbausteine: 'Textbausteine',
  reihenfolge: 'Reihenfolge Position',
  rechnungsnummern: 'Rechnungsnummer',
  empfaenger: 'Empfänger',
  versanddatum: 'Versanddatum'
};

export default function Protokoll(): JSX.Element | null {
  const { vorgang, isLoading } = useVorgangContext();

  const [data, setData] = useState<PaginierteListe<Event>>({ items: [], nextToken: null });

  const fetchData = useCallback(
    (nextToken?: string | null) => {
      makeGraphqlQuery<PaginierteListe<Event>>(queries.eventsByVorgangId, {
        vorgangId: vorgang.id,
        sortDirection: 'DESC',
        limit: 10,
        nextToken
      })
        .then((response) => {
          setData((current) => ({
            items: [...current.items, ...response.items],
            nextToken: response.nextToken
          }));
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    [vorgang.id]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, vorgang.id]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ereignis</TableCell>
              <TableCell>Benutzer</TableCell>
              <TableCell style={{ width: '1%', minWidth: '200px' }}>Datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((event) => (
              <EventRow key={event.id} event={event} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.nextToken && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
          <Button color="primary" variant="contained" disabled={isLoading} onClick={() => fetchData(data.nextToken)}>
            Lade weitere...
          </Button>
        </Box>
      )}
    </>
  );
}

type EventProp = {
  readonly event: Event;
};

function EventRow({ event }: EventProp): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);
  return (
    <>
      <TableRow hover onClick={handleExpandClick} data-testid={'event-row'}>
        <TableCell>
          <IconButton onClick={handleExpandClick} size="large">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {EVENT_TEXTE[event.type ?? '']}
        </TableCell>

        <TableCell>{benutzername(event)}</TableCell>
        <TableCell>{toDateTimeString(event.createdAt)}</TableCell>
      </TableRow>
      {expanded && (
        <TableRow
          sx={(theme) => ({
            backgroundColor: theme.palette.grey['100']
          })}
        >
          <TableCell colSpan={3}>
            {event.type === EVENT_TYPE_GUTACHTEN_GENERIERT ? (
              <GutachtenGeneriertZusatzinformationen event={event} />
            ) : event.type === EVENT_TYPE_BEARBEITER_ZUGEWIESEN ? (
              <BearbeiterZugewiesenZusatzinformationen event={event} />
            ) : event.type === EVENT_TYPE_ANGEBOTSANFRAGE_VERSENDET ||
              event.type === EVENT_TYPE_GUTACHTEN_VERSENDET ||
              event.type === EVENT_TYPE_HIS_VERSENDET ? (
              <VersandtZusatzinformationen event={event} />
            ) : (
              <Aenderungsinformationen event={event} />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

interface ProtokollEintraege {
  [key: string]: string | boolean | null | ProtokollEintraege;
}

type ShowDiffProps = {
  readonly diffType: string;
  readonly diff: ProtokollEintraege;
  readonly original: ProtokollEintraege;
  readonly path?: string[];
};

function ShowDiff({ diffType, diff, original, path = [] }: ShowDiffProps): JSX.Element {
  const addKeysWithNull = (diff: ProtokollEintraege, original: ProtokollEintraege) => {
    Object.keys(original).forEach((diffKey) => {
      if (typeof original[diffKey] === 'object') {
        diff[diffKey] = {};
        //@ts-ignore
        addKeysWithNull(original[diffKey] as ProtokollEintraege, diff[diffKey]);
      } else {
        diff[diffKey] = null;
      }
    });
  };

  const friendlyName = (path: string[]) => {
    return path.map((field) => FELDBEZEICHNUNGEN[field] ?? field).join(' ');
  };

  const toReadableString = (value: string | boolean | object | null): string => {
    if (typeof value === 'boolean') {
      if (value) {
        return 'ja';
      } else {
        return 'nein';
      }
    }
    return String(value);
  };

  if (diffType === 'deleted') {
    addKeysWithNull(diff, original);
  }

  return (
    <>
      {Object.keys(diff).map((diffKey): JSX.Element | null => {
        if (['updatedAt', 'createdAt', 'dat'].includes(diffKey)) {
          return null;
        }
        const currentPath = [...path, diffKey];

        const oldValue = original && original[diffKey];
        const newValue = diff[diffKey];

        const oldValueWasEmpty = !oldValue && typeof newValue !== 'boolean';
        const newValueIsEmpty = !newValue && typeof newValue !== 'boolean';

        // typeof null is 'object', so check for falsy too
        if (newValue && typeof newValue === 'object') {
          return <ShowDiff key={currentPath.join('.')} diffType={diffType} diff={newValue} original={oldValue as ProtokollEintraege} path={currentPath} />;
        }

        return (
          <TableRow key={diffKey} data-testid={diffKey}>
            <TableCell>
              <Typography>{friendlyName(currentPath)}</Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={(theme) => ({
                  color: diffType === 'deleted' || oldValueWasEmpty ? theme.palette.text.disabled : theme.palette.error.dark,
                  textDecoration: 'line-through'
                })}
                data-testid={`${diffKey}-old`}
                aria-label={diffType}
              >
                {oldValueWasEmpty ? 'nicht gesetzt' : toReadableString(oldValue)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={(theme) => {
                  let color = undefined;
                  if (diffType === 'added') {
                    color = theme.palette.success.dark;
                  } else if (diffType === 'updated') {
                    color = theme.palette.info.dark;
                  } else if (newValueIsEmpty) {
                    color = theme.palette.text.disabled;
                  }
                  return {
                    color
                  };
                }}
                data-testid={`${diffKey}-new`}
                aria-label={diffType}
              >
                {newValueIsEmpty ? 'nicht gesetzt' : toReadableString(newValue)}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

function Aenderungsinformationen({ event }: EventProp): JSX.Element {
  //@ts-expect-error JSON.parse entfernen, sobald Events über API Gatewax geholt werden
  const diff = JSON.parse(event.payload ?? '{}');

  return (
    <>
      {diff.newDiff || diff.oldDiff ? (
        <Paper>
          <Table data-testid="diff-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '33%' }}>Feld</TableCell>
                <TableCell sx={{ width: '33%' }}>Vorher</TableCell>
                <TableCell sx={{ width: '33%' }}>Nachher</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ShowDiff key="added" diffType="added" diff={diff.newDiff.added} original={diff.oldDiff.added} />
              <ShowDiff key="updated" diffType="updated" diff={diff.newDiff.updated} original={diff.oldDiff.updated} />
              <ShowDiff key="deleted" diffType="deleted" diff={diff.newDiff.deleted} original={diff.oldDiff.added} />
            </TableBody>
          </Table>
        </Paper>
      ) : (
        'Keine Informationen verfügbar.'
      )}
    </>
  );
}

function GutachtenGeneriertZusatzinformationen({ event }: EventProp): JSX.Element {
  //@ts-expect-error JSON.parse entfernen, sobald Events über API Gatewax geholt werden
  const payload = JSON.parse(event.payload ?? '{}');

  const vorgang = payload.vorgang;
  const gutachten = vorgang.gutachten[0];

  const url = useDateiUrl(gutachten?.key);

  return (
    <Paper>
      <Box p={SPACING_BETWEEN_FORM_FIELDS}>
        <Button startIcon={<GavelIcon />} color="primary" href={url ?? ''} target="_blank">
          Gutachten {gutachten.gutachtennummer} öffnen
        </Button>
      </Box>
    </Paper>
  );
}

function VersandtZusatzinformationen({ event }: EventProp): JSX.Element {
  //@ts-expect-error JSON.parse entfernen, sobald Events über API Gatewax geholt werden
  const payload = JSON.parse(event.payload ?? '{}');

  const emails = [];
  if (payload.email) {
    emails.push(payload.email);
  } else if (payload.emails) {
    emails.push(...payload.emails);
  }

  return (
    <>
      {emails.length > 0 ? (
        <Paper>
          <Box p={SPACING_BETWEEN_FORM_FIELDS}>{`Versandt an ${emails.join(', ')}`}</Box>
        </Paper>
      ) : (
        'Keine Informationen verfügbar.'
      )}
    </>
  );
}

function BearbeiterZugewiesenZusatzinformationen({ event }: EventProp): JSX.Element {
  //@ts-expect-error JSON.parse entfernen, sobald Events über API Gatewax geholt werden
  const payload = JSON.parse(event.payload ?? '{}');
  const { vorgang, vorherigerBearbeiter, neuerBearbeiter } = payload;
  const vorgangsnummer = vorgang.vorgangsnummer;

  return (
    <Paper>
      <Box p={SPACING_BETWEEN_FORM_FIELDS}>
        Der Bearbeiter wurde von{' '}
        <Typography variant="body2" sx={{ display: 'inline' }}>
          {vorherigerBearbeiter}
        </Typography>{' '}
        zu{' '}
        <Typography variant="body2" sx={(theme) => ({ display: 'inline', color: theme.palette.info.dark })}>
          {neuerBearbeiter}
        </Typography>{' '}
        geändert. Die Vorgangsnummer ist nun{' '}
        <Typography variant="body2" sx={(theme) => ({ display: 'inline', color: theme.palette.info.dark })}>
          {vorgangsnummer}
        </Typography>
        .
      </Box>
    </Paper>
  );
}

function benutzername(entitaet: Vorgang | Event): string | null | undefined {
  if (entitaet?.bearbeiter?.given_name && entitaet?.bearbeiter?.family_name) {
    return `${entitaet.bearbeiter.given_name} ${entitaet.bearbeiter.family_name}`;
  }
  return entitaet?.userId;
}
