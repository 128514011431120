import React, { useEffect, useState } from 'react';
import { GutachtenMitUrl } from '../types';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as queries from '../graphql/queries';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/List';
import { Modal } from './Modal';
import { toDateTimeString } from '../shared/dateTime';
import DialogContentText from '@mui/material/DialogContentText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  vorgangId: string | null | undefined;
  fahrgestellnummer: string | null | undefined;
  username: string | null | undefined;
};

export function AlteGutachtenZuFinButton({ vorgangId, fahrgestellnummer, username }: Props): JSX.Element | null {
  const [gutachtenMitUrl, setGutachtenMitUrl] = useState<GutachtenMitUrl[]>([]);
  const [showAlteVorgaengeDialog, setShowAlteVorgaengeDialog] = useState(false);

  useEffect(() => {
    let didCancel = false;

    async function holeAlteVorgaenge() {
      const { items } = await makeGraphqlQuery(queries.sucheGutachten, {
        vorgangId,
        fahrgestellnummer
      });

      if (!didCancel) {
        setGutachtenMitUrl(items);
      }
    }

    if (fahrgestellnummer && username) {
      holeAlteVorgaenge();
    }
    return () => {
      didCancel = true;
    };
  }, [fahrgestellnummer, username, vorgangId]);

  return gutachtenMitUrl.length > 0 ? (
    <>
      <Button sx={{ marginLeft: 'auto' }} color="primary" variant="contained" onClick={() => setShowAlteVorgaengeDialog(true)} startIcon={<ListIcon />}>
        {gutachtenMitUrl.length}
      </Button>

      {showAlteVorgaengeDialog && (
        <Modal
          openModal={showAlteVorgaengeDialog}
          setOpenModal={() => setShowAlteVorgaengeDialog(false)}
          title="Gutachten"
          sx={{ maxWidth: '600px' }}
          body={
            <>
              <DialogContentText>Hier werden alle Gutachten aufgelistet, welche die gleiche Fahrgestellnummer beinhalten</DialogContentText>

              {gutachtenMitUrl.map((gutachten) => (
                <Button
                  fullWidth
                  key={gutachten.gutachtennummer}
                  variant="contained"
                  color="primary"
                  href={gutachten.url}
                  target="_blank"
                  endIcon={<OpenInNewIcon />}
                  sx={{ marginBottom: '1rem' }}
                >
                  {gutachten.gutachtennummer} ({toDateTimeString(gutachten.erstellungsdatum)})
                </Button>
              ))}
            </>
          }
        ></Modal>
      )}
    </>
  ) : null;
}
