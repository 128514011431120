import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  readonly openModal: boolean;
  readonly setOpenModal: (value: boolean) => void;
  readonly title?: React.ReactNode;
  readonly body: React.ReactNode;
  readonly footer?: React.ReactNode;
  readonly ariaLabelledby?: string;
  readonly dataTestid?: string;
  readonly sx?: object;
};

/*
 * EXP24-754
 * Problem: https://github.com/nhn/tui.image-editor/issues/354
 * Workaround: https://github.com/nhn/tui.image-editor/issues/354#issuecomment-720410463
 * Hinweis: verschlechter die Barrierefreiheit
 */
const disableEnforceFocus = true;

export function Modal({ openModal, setOpenModal, title, body, footer, ariaLabelledby, dataTestid, sx = {} }: Props): JSX.Element {
  return (
    <MuiModal
      open={openModal}
      onClose={(event, reason) => (reason ? null : setOpenModal(false))}
      aria-labelledby={ariaLabelledby}
      data-testid={dataTestid}
      disableEnforceFocus={disableEnforceFocus}
      disableAutoFocus={true}
    >
      <Box
        component="div"
        sx={(theme) => ({
          position: 'absolute',
          width: '80%',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(3),
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '90vh',
          overflow: 'auto',
          ...sx
        })}
      >
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          {title ? (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {title}{' '}
                {!footer ? (
                  <IconButton onClick={() => setOpenModal(true)} size="large" sx={{ marginLeft: 'auto' }}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {body}
          </Grid>
        </Grid>

        {footer ? (
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} sx={{ marginTop: '0.1rem' }}>
            {footer}
          </Grid>
        ) : null}
      </Box>
    </MuiModal>
  );
}
