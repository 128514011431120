import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { AKTION_KUNDENDATEN_ERFASSEN, getDefaultPersonMitTyp } from '../frontendConstants';
import WarningSign from './common/WarningSign';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { Besichtigung, Person, Vorgang } from '../types';
import { YesNoFormControl } from './YesNoFormControl';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import SearchPersonUnternehmen from './common/SearchPersonUnternehmen';
import { PersonenTyp } from '../shared/constants';
import { Moment } from 'moment';
import { CustomDateTimePicker } from './common/customDatePickers';
import { Modal } from './Modal';
import { getMessageFromError } from '../shared/throw';
import { ERROR_MESSAGE } from './common/Alert';
import { useSnackbar } from 'notistack';
import { aktualisierePerson } from '../domain/aktualisierePerson';
import { PersonEingabeMaske } from './PersonEingabeMaske/PersonEingabeMaske';
import { vermittler } from '../../cypress/testdaten';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly aktualisiereBesichtigungsdaten: (besichtigung: Partial<Besichtigung>) => void;
  readonly besichtigung: Partial<Besichtigung>;
  readonly onClose: () => void;
  readonly title: string;
};

export function Besichtigungdialog({ vorgang, isLoading, setLoading, aktualisiereBesichtigungsdaten, besichtigung, onClose, title }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();
  const [aktuellerVermittler, setAktuellerVermittler] = useState<Person>(besichtigung?.vermittler ?? getDefaultPersonMitTyp(PersonenTyp.VERMITTLER));
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });
  const [besichtigungsdatum, setBesichtigungsdatum] = useState<Partial<Besichtigung>>({
    ...besichtigung,
    besichtigungstermin: besichtigung.besichtigungstermin ?? new Date().toISOString()
  });

  useEffect(() => {
    setAktuellerVermittler(besichtigungsdatum.vermittler ?? getDefaultPersonMitTyp(PersonenTyp.VERMITTLER));
  }, [besichtigungsdatum]);

  const uebernehmeAuftraggeberAdresse = () => {
    if (vorgang?.auftraggeber) {
      const auftraggeber = vorgang.auftraggeber;
      setBesichtigungsdatum((prev) => ({
        ...prev,
        strasse: auftraggeber.strasse,
        ort: auftraggeber.ort,
        plz: auftraggeber.plz
      }));
    }
  };

  const handleVermittlerAnlegenDialog = () => {
    setAktuellerVermittler(getDefaultPersonMitTyp(PersonenTyp.VERMITTLER));
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleVermittlerLoeschen = () => {
    setBesichtigungsdatum((prev) => ({ ...prev, vermittlerId: null, vermittler: null }));
  };

  const handleVermittlerAendern = (person: Person) => {
    setLoading(true);

    aktualisierePerson({
      ...person,
      mandant: vorgang.mandant
    })
      .then((neuePerson) => {
        setBesichtigungsdatum((prev) => ({ ...prev, vermittlerId: neuePerson.id, vermittler: person }));
        setDialog({ anlegen: false, aendern: false });
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Vermittler konnte nicht gespeichert werden', ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_KUNDENDATEN_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  return (
    <Modal
      sx={{ maxWidth: '600px' }}
      openModal={true}
      setOpenModal={onClose}
      title={title}
      body={
        <>
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Straße und Hausnummer"
                value={besichtigungsdatum.strasse ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  setBesichtigungsdatum({ ...besichtigungsdatum, strasse: event.target.value });
                }}
                fullWidth
                data-testid="besichtigungsdaten-strasse"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                label="PLZ"
                value={besichtigungsdatum.plz ?? ''}
                disabled={isDisabled}
                onChange={(event) => setBesichtigungsdatum({ ...besichtigungsdatum, plz: event.target.value })}
                fullWidth
                data-testid="besichtigungsdaten-plz"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="standard"
                label="Ort"
                value={besichtigungsdatum.ort ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  setBesichtigungsdatum({ ...besichtigungsdatum, ort: event.target.value });
                }}
                fullWidth
                data-testid="besichtigungsdaten-ort"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button color="primary" variant="contained" onClick={uebernehmeAuftraggeberAdresse} disabled={!vorgang?.auftraggeberId}>
                Auftraggeberadresse übernehmen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <SearchPersonUnternehmen
                mandantId={vorgang?.mandant}
                isDisabled={isDisabled}
                label="Vermittler suchen"
                onSelect={(person) => setBesichtigungsdatum({ ...besichtigungsdatum, vermittlerId: person.id, vermittler: person as Person })}
                testPraefix="vermittler"
                personenUnternehmenTyp={PersonenTyp.VERMITTLER}
              />
            </Grid>
            <Grid item xs={12}>
              <WarningSign
                show={Boolean(aktuellerVermittler === getDefaultPersonMitTyp(PersonenTyp.VERMITTLER) && besichtigungsdatum.vermittlerId)}
                text="Der Vermittler wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
              />
              <FormControl variant="standard" disabled={true} fullWidth>
                <InputLabel htmlFor="standard-adornment-password">zugeordneter Vermittler</InputLabel>
                <Input
                  data-testid="besichtigungsdaten-vermittler"
                  value={aktuellerVermittler ? `${aktuellerVermittler?.vorname ?? ''} ${aktuellerVermittler?.nachname ?? ''}` : ''}
                  onChange={() => undefined}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!besichtigungsdatum.vermittlerId || isDisabled}
                        onClick={() => {
                          setDialog((current) => ({ ...current, aendern: true }));
                        }}
                        data-testid="vermittler-bearbeiten"
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleVermittlerAnlegenDialog();
                        }}
                        disabled={isDisabled}
                        data-testid="vermittler-anlegen"
                        size="large"
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <IconButton
                        disabled={!besichtigungsdatum.vermittlerId || isDisabled}
                        onClick={() => {
                          handleVermittlerLoeschen();
                        }}
                        data-testid="vermittler-loeschen"
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDateTimePicker
                fullWidth
                disabled={isDisabled}
                value={besichtigungsdatum.besichtigungstermin ? new Date(besichtigungsdatum.besichtigungstermin) : new Date()}
                onChange={(date: Moment | Date | null) => {
                  setBesichtigungsdatum({ ...besichtigungsdatum, besichtigungstermin: date ? date.toISOString() : null });
                }}
                label="Besichtigungstermin"
                data-testid="besichtigungsdaten-besichtigungstermin"
              />
            </Grid>
            <Grid item xs={12}>
              <YesNoFormControl
                labelText="Fahrzeug in demontiertem Zustand"
                value={besichtigungsdatum.demontierterZustand}
                disabled={isDisabled}
                onChange={(jaNein) => {
                  setBesichtigungsdatum({ ...besichtigungsdatum, demontierterZustand: jaNein });
                }}
                dataTestid="besichtigungsdaten-demontiert"
                fullWidth
              />
            </Grid>
          </Grid>

          {(dialog.anlegen || dialog.aendern) && (
            <PersonEingabeMaske
              personDatensatz={dialog.aendern ? (besichtigungsdatum?.vermittler ?? null) : null}
              typ={PersonenTyp.VERMITTLER}
              onClose={() => {
                setDialog({ anlegen: false, aendern: false });
              }}
              aktualisierePerson={handleVermittlerAendern}
              isDisabled={isDisabled}
              mandant={vorgang.mandant}
              testPraefix="vermittler"
            />
          )}
        </>
      }
      footer={
        <>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={() => onClose()} data-testid="besichtigungsdaten-abbrechen">
              Abbrechen
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                aktualisiereBesichtigungsdaten(besichtigungsdatum);
                onClose();
              }}
              data-testid="besichtigungsdaten-speichern"
            >
              Übernehmen
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}
