type ErrorLike = { message: string };
type ComplexError = { message?: string; errors?: { message: string }[] };
type GraphQlError = string | ComplexError | ErrorLike;

/*
 * @deprecated benutze {@code getMessageFromError} stattdessen
 */
export function findError(error: ErrorLike | string | unknown): GraphQlError {
  if (typeof error === 'string') {
    return findErrorInString(error);
  } else if (hasMessageProperty(error)) {
    return findErrorInString(error.message);
  } else {
    return findErrorInComplexObject(error);
  }
}

export function getMessageFromError(error: any | { [message: string]: unknown }): string {
  if (typeof error === 'string') {
    return error;
  }

  if ('message' in error && typeof error.message === 'string') {
    return error.message;
  }
  if ('data' in error) {
    if ('message' in error.data) {
      return error.data.message;
    }
    return error.data;
  }
  return String(error);
}

function findErrorInString(error: string): GraphQlError {
  if (isJsonString(error)) {
    return findError(JSON.parse(error));
  }
  return { message: error };
}

function findErrorInComplexObject(error: ComplexError | unknown): GraphQlError {
  if (hasErrorsProperty(error) && Array.isArray(error.errors)) {
    return findError(error.errors[0]);
  } else if (hasMessageProperty(error) && isJsonString(error.message || '')) {
    return findErrorInString(error.message || '');
  } else if (hasMessageProperty(error) && error.message) {
    return { message: error.message };
  } else if (Array.isArray(error)) {
    // GraphQL
    return findError(error[0]);
  }
  return { message: String(error) };
}

function hasMessageProperty(value: unknown): value is ErrorLike {
  return typeof value === 'object' && value !== null && 'message' in value;
}

function hasErrorsProperty(value: unknown): value is ComplexError {
  return typeof value === 'object' && value !== null && 'errors' in value;
}

function isJsonString(object: string): boolean {
  try {
    JSON.parse(object);
  } catch (error) {
    return false;
  }
  return true;
}
