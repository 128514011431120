import { ApiRequest, Benutzer, Benutzerprofil, PaginierteListe, ZahlungseingangHistorie, Zahlungsimport } from '../types';

export const BENUTZERPROFIL = (username: string) => `/benutzerprofil/${username}`;

export const ZAHLUNGSEINGANG_IMPORT = '/zahlungseingang/import';
export const ZAHLUNGSEINGANG_HISTORIE = '/zahlungseingang/historie';
export const ZAHLUNGSEINGANG_HISTORIE_LOESCHEN = '/zahlungseingang/historie';
export const ZAHLUNGSEINGANG_LOESCHEN = '/zahlungseingang/loeschen';

export const benutzerprofil = {
  get: (username: string): ApiRequest<Benutzer & Benutzerprofil> => {
    return {
      url: protectedPath(BENUTZERPROFIL(username)),
      method: 'GET'
    };
  },
  patch: (username: string, benutzerprofil: Partial<Benutzer>): ApiRequest<Benutzer & Benutzerprofil> => {
    return {
      url: protectedPath(BENUTZERPROFIL(username)),
      method: 'PATCH',
      body: benutzerprofil
    };
  }
};

export const zahlungseingang = {
  importPostUrl: (body: string): ApiRequest<Zahlungsimport> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_IMPORT),
      method: 'POST',
      headers: {
        'content-type': 'text/csv'
      },
      body
    };
  },
  getUrl: (jahr: number, nextToken?: string | null): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(`${ZAHLUNGSEINGANG_HISTORIE}?jahr=${jahr}&nextToken=${nextToken ?? ''}`),
      method: 'GET'
    };
  },
  historieLoeschenPostUrl: (jahr: string, importDatum: string): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_HISTORIE_LOESCHEN),
      method: 'DELETE',
      body: {
        jahr,
        importDatum
      }
    };
  },
  loeschePostUrl: (id: string, jahr: string, importDatum: string): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_LOESCHEN),
      method: 'POST',
      body: {
        id,
        jahr,
        importDatum
      }
    };
  }
};

function protectedPath(path: string) {
  return `protected${path}`;
}
