import React, { useEffect } from 'react';
import { useVorgangContext } from '../contexts/vorgangContext';
import { useHistory } from 'react-router';
import { useUser } from '../hooks/useUser';
import { signOut } from '../shared/Auth';

export function LogoutPage(): JSX.Element {
  const history = useHistory();

  const { hatAenderungen, setzeVorgang } = useVorgangContext();
  const { resetUser } = useUser();

  useEffect(() => {
    if (!hatAenderungen) {
      history.replace('/dashboard');
    }

    return () => {
      if (!hatAenderungen) {
        signOut().then(() => {
          setzeVorgang();
          resetUser();
        });
      }
    };
  }, [hatAenderungen, history, setzeVorgang, resetUser]);

  return <>abmelden...</>;
}
