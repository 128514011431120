import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import Formular from '../../components/common/Formular';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import { COGNITO_GRUPPE_ADMIN, COGNITO_GRUPPE_QUALITAETSSICHERUNG, COGNITO_GRUPPE_SACHVERSTAENGIGER, COGNITO_GRUPPE_VERWALTUNG } from '../../shared/constants';
import BenutzerAnlegenModal from '../../components/modal/BenutzerAnlegenModal';
import BenutzerLoeschenDialog from '../../components/dialog/BenutzerLoeschenDialog';
import MandantenAuswahl from '../../components/common/MandantenAuswahl';
import { useUser } from '../../hooks/useUser';
import * as queries from '../../graphql/queries';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import { deprecated_Benutzer } from '../../types';
import { Link } from 'react-router-dom';

type UserMitGruppe = deprecated_Benutzer & { gruppe: string };
type UserMitGruppen = deprecated_Benutzer & { gruppen: string[] };

export default function MandantenUserVerwaltung(): JSX.Element | null {
  const { user, isAdmin } = useUser();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [mandantenUser, setMandantenUser] = useState<UserMitGruppen[]>([]);
  const [neuerBenutzer, setNeuerBenutzer] = useState<deprecated_Benutzer>({
    id: '',
    email: '',
    family_name: '',
    given_name: '',
    kuerzel: '',
    mandant: ''
  });
  const [openBenutzerAnlegenDialog, setOpenBenutzerAnlegenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<deprecated_Benutzer | null>();
  const [userToEdit, setUserToEdit] = useState<deprecated_Benutzer | null>();
  const [aktuellerMandant, setAktuellerMandant] = useState('');

  const holeUserAusCognito = useCallback(async (): Promise<UserMitGruppe[]> => {
    let qualitatssicherungUsers: deprecated_Benutzer[] = [];
    let verwaltungUsers: deprecated_Benutzer[] = [];
    let adminUsers: deprecated_Benutzer[] = [];
    let sachverstaendigerUsers: deprecated_Benutzer[] = [];
    let usersWithoutGroups: deprecated_Benutzer[] = [];

    if (aktuellerMandant !== '') {
      qualitatssicherungUsers = await makeGraphqlQuery(queries.holeBenutzerlisteInGruppe, {
        gruppe: COGNITO_GRUPPE_QUALITAETSSICHERUNG,
        mandant: aktuellerMandant
      });
      verwaltungUsers = await makeGraphqlQuery(queries.holeBenutzerlisteInGruppe, {
        gruppe: COGNITO_GRUPPE_VERWALTUNG,
        mandant: aktuellerMandant
      });
      adminUsers = await makeGraphqlQuery(queries.holeBenutzerlisteInGruppe, {
        gruppe: COGNITO_GRUPPE_ADMIN,
        mandant: aktuellerMandant
      });
      sachverstaendigerUsers = await makeGraphqlQuery(queries.holeBenutzerlisteInGruppe, {
        gruppe: COGNITO_GRUPPE_SACHVERSTAENGIGER,
        mandant: aktuellerMandant
      });
      usersWithoutGroups = await makeGraphqlQuery(queries.holeBenutzerliste, {
        mandant: aktuellerMandant
      });
    }

    return [
      ...adminUsers.map((user) => {
        return { ...user, gruppe: COGNITO_GRUPPE_ADMIN };
      }),
      ...verwaltungUsers.map((user) => {
        return { ...user, gruppe: COGNITO_GRUPPE_VERWALTUNG };
      }),
      ...sachverstaendigerUsers.map((user) => {
        return { ...user, gruppe: COGNITO_GRUPPE_SACHVERSTAENGIGER };
      }),
      ...qualitatssicherungUsers.map((user) => {
        return { ...user, gruppe: COGNITO_GRUPPE_QUALITAETSSICHERUNG };
      }),
      ...usersWithoutGroups.map((user) => {
        return { ...user, gruppe: '' };
      })
    ];
  }, [aktuellerMandant]);

  const holeAlleUserFuerMandant = useCallback(async () => {
    setIsLoading(true);
    const users = await holeUserAusCognito();
    const userMitGruppen = ermittleGruppen(users);
    setMandantenUser(userMitGruppen);
    setIsLoading(false);
  }, [holeUserAusCognito]);

  useEffect(() => {
    if (user && !isAdmin) {
      history.push('/');
    } else {
      holeAlleUserFuerMandant();
    }
  }, [user, isAdmin, history, aktuellerMandant, holeAlleUserFuerMandant]);

  const hatGruppe = (zuPruefendesRecht: string, user: UserMitGruppen) => {
    if (user.gruppen && user.gruppen.length > 0) {
      return user.gruppen.includes(zuPruefendesRecht);
    }
    return false;
  };

  const handleChangeGruppe = async (zuVeraenderndeRecht: string, user: UserMitGruppen) => {
    const hatGruppeBereits = user.gruppen.includes(zuVeraenderndeRecht);

    let erfolgreich: boolean;
    if (hatGruppeBereits) {
      erfolgreich = await entferneGruppe(zuVeraenderndeRecht, user);
    } else {
      erfolgreich = await fuegeGruppeHinzu(zuVeraenderndeRecht, user);
    }

    if (erfolgreich) {
      aktualisiereUserListe(zuVeraenderndeRecht, user);
    } else {
      enqueueSnackbar(
        `Die Gruppe ${zuVeraenderndeRecht.toUpperCase()} ${
          hatGruppeBereits ? `konnte bei dem ${user.id} nicht entfernt` : `konnte dem ${user.id} nicht hinzugefügt`
        } werden.`,
        ERROR_MESSAGE
      );
    }
  };

  const ermittleGruppen = (users: UserMitGruppe[]) => {
    const userMitGruppen: UserMitGruppen[] = [];
    users.forEach((benutzer) => {
      if (!userMitGruppen.some((user) => user.id === benutzer.id)) {
        userMitGruppen.push({ ...benutzer, gruppen: [] });
      }

      const tempUser = userMitGruppen.find((user) => user.id === benutzer.id);
      tempUser?.gruppen.push(benutzer.gruppe);
    });
    return userMitGruppen;
  };

  const aktualisiereUserListe = (gruppe: string, aktualisierterUser: UserMitGruppen) => {
    const aktuellerUser = mandantenUser.find((user) => user.id === aktualisierterUser.id);

    if (!aktuellerUser) {
      return;
    }

    const hatRechtBereits = Boolean(aktuellerUser.gruppen.includes(gruppe));

    if (hatRechtBereits) {
      aktuellerUser.gruppen = aktuellerUser.gruppen.filter((recht: string) => recht !== gruppe);
    } else {
      aktuellerUser.gruppen.push(gruppe);
    }

    setMandantenUser([...mandantenUser]);
    enqueueSnackbar(
      `Die Gruppe ${gruppe.toUpperCase()} ${
        hatRechtBereits
          ? `wurde bei dem Benutzer ${aktualisierterUser.id} erfolgreich entfernt`
          : `wurde dem Benutzer ${aktualisierterUser.id} erfolgreich hinzugefügt`
      }.`,
      SUCCESS_MESSAGE_AUTO_HIDE
    );
  };

  const entferneGruppe = async (zuVeraenderndeRecht: string, user: deprecated_Benutzer): Promise<boolean> => {
    setIsLoading(true);
    const erfolgreich = await makeGraphqlQuery(queries.entferneUserAusCognitoGruppe, {
      gruppe: zuVeraenderndeRecht,
      userId: user.id,
      mandant: aktuellerMandant
    });
    setIsLoading(false);
    return erfolgreich;
  };

  const fuegeGruppeHinzu = async (zuVeraenderndeRecht: string, user: deprecated_Benutzer): Promise<boolean> => {
    setIsLoading(true);
    const erfolgreich = await makeGraphqlQuery(queries.fuegeUserZuCognitoGruppeHinzu, {
      gruppe: zuVeraenderndeRecht,
      userId: user.id,
      mandant: aktuellerMandant
    });
    setIsLoading(false);
    return erfolgreich;
  };

  const updateUserInCognito = async () => {
    setIsLoading(true);

    const erfolgreich = await makeGraphqlQuery(queries.aktualisiereBenutzerInCognito, {
      id: userToEdit?.id,
      family_name: userToEdit?.family_name,
      given_name: userToEdit?.given_name,
      mandant: userToEdit?.mandant,
      email: userToEdit?.email
    });

    setIsLoading(false);
    setOpenEditDialog(false);
    erfolgreich && holeAlleUserFuerMandant();
    erfolgreich
      ? enqueueSnackbar(`Die Daten des Benutzers ${userToEdit?.id} wurden erfolgreich geändert.`, SUCCESS_MESSAGE_AUTO_HIDE)
      : enqueueSnackbar(`Die Daten des Benutzers ${userToEdit?.id} konnten nicht geändert werden.`, ERROR_MESSAGE);
  };

  const fuegeBenutzerZuCognitoHinzu = async (benutzer: deprecated_Benutzer) => {
    setIsLoading(true);
    const erfolgreich = await makeGraphqlQuery(queries.fuegeBenutzerZuCognitoHinzu, {
      id: benutzer.id,
      family_name: benutzer.family_name,
      given_name: benutzer.given_name,
      kuerzel: benutzer.kuerzel,
      mandant: benutzer.mandant,
      email: benutzer.email
    });
    setIsLoading(false);
    setOpenBenutzerAnlegenDialog(false);

    if (erfolgreich) {
      await holeAlleUserFuerMandant();
      enqueueSnackbar(`Der Benutzer ${benutzer.id} wurde erfolgreich angelegt.`, SUCCESS_MESSAGE_AUTO_HIDE);
    } else {
      enqueueSnackbar(`Der Benutzer ${benutzer.id} konnte nicht angelegt werden.`, ERROR_MESSAGE);
    }
  };

  const entferneBenutzerAusCognito = async (user: deprecated_Benutzer) => {
    setIsLoading(true);

    const erfolgreich = await makeGraphqlQuery(queries.entferneBenutzerAusCognito, { userId: user.id });
    if (erfolgreich) {
      await holeAlleUserFuerMandant();
      enqueueSnackbar(`Der Benutzer ${user.id} wurde erfolgreich gelöscht.`, SUCCESS_MESSAGE_AUTO_HIDE);
    } else {
      enqueueSnackbar(`Der Benutzer ${user.id} konnte nicht gelöscht werden.`, ERROR_MESSAGE);
    }

    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  const resetPassword = async () => {
    setIsLoading(false);
    const erfolgreich = await makeGraphqlQuery(queries.setzePasswortFuerBenutzerInCognitoZurueck, { userId: userToEdit?.id });

    if (erfolgreich) {
      enqueueSnackbar(`Das Passwort für ${userToEdit?.id} wurde erfolgreich zurück gesetzt.`, SUCCESS_MESSAGE_AUTO_HIDE);
    } else {
      enqueueSnackbar(`Das Passwort für ${userToEdit?.id} konnte nicht zurückgesetzt werden.`, ERROR_MESSAGE);
    }
    setIsLoading(false);
  };

  return user && isAdmin ? (
    <>
      <Toolbar>
        <Typography
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'nowrap',
            alignItems: 'center'
          }}
          variant="h6"
        >
          Admin
        </Typography>
        <MandantenAuswahl
          onSelectMandant={(mandant) => {
            setAktuellerMandant(mandant);
          }}
          gruppe={COGNITO_GRUPPE_ADMIN}
        />
      </Toolbar>
      <Formular ueberschrift="Benutzerverwaltung">
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <Table key={0}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '300px' }}>Benutzer</TableCell>
                  <TableCell>Sachverständiger</TableCell>
                  <TableCell>Qualitätssicherung</TableCell>
                  <TableCell>Verwaltung</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {mandantenUser.map((user) => (
                  <TableRow key={user.id} data-testid={user.id}>
                    <TableCell>
                      <Link to={`/profil/${user.id}`}>{user.id}</Link>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={hatGruppe(COGNITO_GRUPPE_SACHVERSTAENGIGER, user)}
                        aria-checked={hatGruppe(COGNITO_GRUPPE_SACHVERSTAENGIGER, user)}
                        onChange={() => {
                          handleChangeGruppe(COGNITO_GRUPPE_SACHVERSTAENGIGER, user);
                        }}
                        color="primary"
                        data-testid="sachverstaendiger"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={hatGruppe(COGNITO_GRUPPE_QUALITAETSSICHERUNG, user)}
                        aria-checked={hatGruppe(COGNITO_GRUPPE_QUALITAETSSICHERUNG, user)}
                        onChange={() => {
                          handleChangeGruppe(COGNITO_GRUPPE_QUALITAETSSICHERUNG, user);
                        }}
                        color="primary"
                        data-testid="qualitaetssicherung"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={hatGruppe(COGNITO_GRUPPE_VERWALTUNG, user)}
                        aria-checked={hatGruppe(COGNITO_GRUPPE_VERWALTUNG, user)}
                        onChange={() => {
                          handleChangeGruppe(COGNITO_GRUPPE_VERWALTUNG, user);
                        }}
                        color="primary"
                        data-testid="verwaltung"
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={hatGruppe(COGNITO_GRUPPE_ADMIN, user)}
                        onChange={() => {
                          handleChangeGruppe(COGNITO_GRUPPE_ADMIN, user);
                        }}
                        color="primary"
                        data-testid="admin"
                        aria-checked={hatGruppe(COGNITO_GRUPPE_ADMIN, user)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setUserToEdit({
                            ...user,
                            mandant: aktuellerMandant
                          });
                          setOpenEditDialog(true);
                        }}
                        size="large"
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setUserToDelete(user);
                          setOpenDeleteDialog(true);
                        }}
                        size="large"
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              data-testid="benutzerkontoAnlegen"
              onClick={() => {
                setNeuerBenutzer({
                  id: '',
                  email: '',
                  family_name: '',
                  given_name: '',
                  kuerzel: '',
                  mandant: aktuellerMandant
                });
                setOpenBenutzerAnlegenDialog(true);
              }}
            >
              Benutzerkonto anlegen
            </Button>
          </Grid>
        </Grid>
      </Formular>
      <LoadingIndicator isLoading={isLoading} />
      {openBenutzerAnlegenDialog && (
        <BenutzerAnlegenModal
          openBenutzerAnlegenDialog={openBenutzerAnlegenDialog}
          setOpenBenutzerAnlegenDialog={setOpenBenutzerAnlegenDialog}
          neuerBenutzer={neuerBenutzer}
          isLoading={isLoading}
          setNeuerBenutzer={setNeuerBenutzer}
          fuegeBenutzerZuCognitoHinzu={fuegeBenutzerZuCognitoHinzu}
        />
      )}
      {openEditDialog && userToEdit && (
        <BenutzerAnlegenModal
          openBenutzerAnlegenDialog={openEditDialog}
          setOpenBenutzerAnlegenDialog={setOpenEditDialog}
          neuerBenutzer={userToEdit}
          isLoading={isLoading}
          setNeuerBenutzer={setUserToEdit}
          fuegeBenutzerZuCognitoHinzu={updateUserInCognito}
          resetPassword={resetPassword}
        />
      )}
      {openDeleteDialog && userToDelete && (
        <BenutzerLoeschenDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          userToDelete={userToDelete}
          entferneBenutzerAusCognito={entferneBenutzerAusCognito}
        />
      )}
    </>
  ) : null;
}
