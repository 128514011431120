import React from 'react';
import { Amplify } from '@aws-amplify/core';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { WithAuthenticator } from './aws-amplify-react';
import IconButton from '@mui/material/IconButton';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { theme } from './assets/theme';
import { Layout } from './components/Layout/Layout';
import UserContextProvider from './contexts/userContext';
import { Dashboard } from './screens/Dashboard/Dashboard';
import Kundendaten from './screens/Vorgang/Kundendaten';
import Unterschriften from './screens/Vorgang/Unterschriften';
import Fotos from './screens/Vorgang/Fotos';
import Gutachten from './screens/Vorgang/Gutachten';
import Protokoll from './screens/Vorgang/Protokoll';
import Mandanten from './screens/Administration/Mandanten';
import Bewertung from './screens/Vorgang/Werte';
import Dokumente from './screens/Vorgang/Dokumente';
import Schadenbemerkungen from './screens/Vorgang/Schadenbemerkungen';
import Profil from './screens/Profil';
import Fahrzeug from './screens/Vorgang/Fahrzeug';
import Personen from './screens/Stammdaten/Personen';
import Unternehmen from './screens/Stammdaten/Unternehmen';
import VorgangContextProvider from './contexts/vorgangContext';
import UnfalldatenView from './screens/Vorgang/UnfalldatenView';
import TextbausteineAdmin from './screens/Administration/TextbausteineAdmin';
import MandantenConfig from './screens/Administration/MandantenConfig';
import UserVerwaltung from './screens/Administration/FranchiseUserVerwaltung';
import MandantenUserVerwaltung from './screens/Administration/MandantenUserVerwaltung';
import VorgangPage from './pages/Vorgang/VorgangPage';
import { LogoutPage } from './pages/LogoutPage';
import { environment } from './env';
import { ZahlungsimportSeite } from './pages/Zahlungsimport/ZahlungsimportSeite';
import { RechnungPage } from './pages/Vorgang/RechnungPage';

Amplify.configure(environment);

function AuthenticatedRoutes(): JSX.Element {
  return (
    <WithAuthenticator>
      <VorgangContextProvider>
        <Layout>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              path="/vorgang/:id"
              render={({ match: { url } }) => (
                <VorgangPage>
                  <Route path={`${url}/kunde`} exact component={Kundendaten} />
                  <Route path={`${url}/unfalldaten`} exact component={UnfalldatenView} />
                  <Route path={`${url}/fahrzeug`} exact component={Fahrzeug} />
                  <Route path={`${url}/unterschriften`} exact component={Unterschriften} />
                  <Route path={`${url}/fotos`} exact component={Fotos} />
                  <Route path={`${url}/dokumente`} exact component={Dokumente} />
                  <Route path={`${url}/rechnungen`} exact component={RechnungPage} />
                  <Route path={`${url}/gutachten`} exact component={Gutachten} />
                  <Route path={`${url}/protokoll`} exact component={Protokoll} />
                  <Route path={`${url}/bewertung`} exact component={Bewertung} />
                  <Route path={`${url}/schadenbemerkungen`} exact component={Schadenbemerkungen} />
                  <Route path="**/logout" component={LogoutPage} />
                </VorgangPage>
              )}
            />

            <Route exact path="/admin/mandanten" component={Mandanten} />
            <Route exact path="/admin/userverwaltung" component={UserVerwaltung} />
            <Route exact path="/admin/mandantenconfig" component={MandantenConfig} />
            <Route exact path="/admin/textbausteine" component={TextbausteineAdmin} />
            <Route exact path="/admin/mandantenbenutzerverwaltung" component={MandantenUserVerwaltung} />

            <Route exact path="/profil/:username" component={Profil} />
            <Route exact path="/zahlungsimport" component={ZahlungsimportSeite} />

            <Route exact path="/stammdaten/person" component={Personen} />
            <Route exact path="/stammdaten/unternehmen" component={Unternehmen} />
            <Route exact path="**/logout" component={LogoutPage} />
          </Switch>
        </Layout>
      </VorgangContextProvider>
    </WithAuthenticator>
  );
}

export function App(): JSX.Element {
  const notistackRef = React.createRef<SnackbarProvider>();

  const createClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            action={(key) => (
              <IconButton onClick={createClickDismiss(key)} data-testid="benachrichtigungSchliessenDruecker" size="large">
                <CloseIcon color="secondary" fontSize="small" />
              </IconButton>
            )}
            ref={notistackRef}
          >
            <Router>
              <AuthenticatedRoutes />
            </Router>
          </SnackbarProvider>
        </UserContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
