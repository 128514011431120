/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { useEffect } from 'react';
import { SignIn } from './SignIn';
import { RequireNewPassword } from './RequireNewPassword';
import { ForgotPassword } from './ForgotPassword';
import { AuthState, EventType } from './types';
import { Logo } from '../components/common/Logo';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from '../components/common/Alert';
import Box from '@mui/material/Box';
import { getCurrentUser, signOut } from '../shared/Auth';
import { CognitoUser } from '../shared/constants';

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';

const messageMap = [
  { message: 'Benutzer existiert nicht', regexp: /user.*not.*exist/i },
  { message: 'Benutzer existiert bereits', regexp: /user.*already.*exist/i },
  { message: 'Benutzername oder Passwort falsch', regexp: /incorrect.*username.*password/i },
  { message: 'Falsche Passwortformat', regexp: /validation.*password/i }
];

const loginHeaderCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> svg': {
    width: '400px'
  }
};

export function errorMessageMapper(message: string) {
  const match = messageMap.filter((entry) => entry.regexp.test(message));
  if (match.length === 0) {
    return message;
  }
  return match[0].message;
}

export interface IAuthenticatorProps {
  authState?: string;
  errorMessage?: (message: string) => string;
  hide?: any[];
  hideDefault?: boolean;
  onStateChange?: (authState: AuthState, data?: any) => void;
}

export interface IAuthenticatorState {
  authData?: Partial<CognitoUser>;
  authState: string;
  error?: string | null;
  showToast?: boolean;
  errorTimestamp?: number | null;
}

const default_children = [
  <SignIn key="SignIn" />,
  <RequireNewPassword key="RequireNewPassword" />,
  <ForgotPassword key="ForgotPassword" />
];

export class Authenticator extends React.Component<IAuthenticatorProps, IAuthenticatorState> {
  public _initialAuthState: string;
  public _isMounted: boolean;

  constructor(props: IAuthenticatorProps) {
    super(props);

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleAuthEvent = this.handleAuthEvent.bind(this);

    this._initialAuthState = this.props.authState ?? 'signIn';
    this._isMounted = false;
    this.state = { authState: 'loading' };
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkUser() {
    return getCurrentUser()
      .then((user) => {
        if (!this._isMounted) {
          return;
        }
        this.handleStateChange('signedIn', user);
      })
      .catch(() => {
        if (!this._isMounted) {
          return;
        }
        let cachedAuthState = null;
        try {
          cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
        } catch (e) {
          console.debug('Failed to get the auth state from local storage', e);
        }
        const promise = cachedAuthState === 'signedIn' ? signOut() : Promise.resolve();
        promise
          .then(() => this.handleStateChange(this._initialAuthState))
          .catch((e) => {
            console.debug('Failed to sign out', e);
          });
      });
  }

  handleStateChange(state: any, data?: any) {
    if (state === this.state.authState) {
      return;
    }

    if (state === 'signedOut') {
      state = 'signIn';
    }
    try {
      localStorage.setItem(AUTHENTICATOR_AUTHSTATE, state);
    } catch (e) {
      console.debug('Failed to set the auth state into local storage', e);
    }

    if (this._isMounted) {
      this.setState({
        authState: state,
        authData: data,
        error: null,
        showToast: false,
        errorTimestamp: null
      });
    }
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data);
    }
  }

  handleAuthEvent(event: EventType, showToast = true) {
    if (event.type === 'error') {
      const map = this.props.errorMessage ?? errorMessageMapper;
      const message = typeof map === 'string' ? map : map(event.data);
      this.setState({ error: message, showToast, errorTimestamp: Date.now() });
    }
  }

  render() {
    const { authState, authData } = this.state;
    const messageMap = this.props.errorMessage ?? errorMessageMapper;

    const { hideDefault } = this.props;
    let { hide = [] } = this.props;

    if (hideDefault) {
      hide = hide.concat([SignIn, RequireNewPassword, ForgotPassword]);
    }

    const error = this.state.error;

    return (
      <>
        <Box component="div" sx={loginHeaderCss} className="login-header">
          <Logo />
        </Box>
        <div>
          <Toast key={this.state.errorTimestamp} showToast={this.state.showToast} error={error} />
          {hideDefault
            ? []
            : React.Children.map(default_children, (child) => {
                return React.cloneElement(child, {
                  key: 'aws-amplify-authenticator-default-children-' + child.key,
                  messageMap,
                  authState,
                  authData,
                  onStateChange: this.handleStateChange,
                  onAuthEvent: this.handleAuthEvent,
                  hide
                });
              })}
        </div>
      </>
    );
  }
}

type ToastProps = {
  showToast: boolean | undefined;
  error?: string | null;
};

function Toast(props: ToastProps) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.showToast) {
      enqueueSnackbar(props.error, ERROR_MESSAGE);
    }
  }, [enqueueSnackbar, props.error, props.showToast]);

  return null;
}
