import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_BOXES } from '../../components/common/spacings';
import { Besichtigungsdaten } from '../../components/Besichtigungsdaten';
import { Auftraggeber } from '../../components/Auftraggeber';
import { Fahrzeughalter } from '../../components/Fahrzeughalter';
import { Besichtigung, BesichtigungenConnection } from '../../types';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Besichtigungdialog } from '../../components/Besichtigungsdialog';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { ERROR_MESSAGE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';

export default function Kundendaten(): JSX.Element {
  const { vorgang, isLoading, setLoading, aktualisiereVorgang, speichereVorgang, setzeVorgang } = useVorgangContext();

  const { enqueueSnackbar } = useSnackbar();

  const [besichtigung, setBesichtigung] = useState<Partial<Besichtigung> | null>(null);

  async function aktualisiereBesichtigungsdaten(besichtigung: Partial<Besichtigung>) {
    try {
      setLoading(true);

      const aktualisierteBesichtigung: Besichtigung = await makeGraphqlQuery(mutations.aktualisiereBesichtigung, {
        input: JSON.stringify({
          ...besichtigung,
          vorgangId: vorgang.id
        })
      });

      let besichtigungen: Besichtigung[];

      if (!besichtigung.id) {
        besichtigungen = [...vorgang.besichtigungen.items, aktualisierteBesichtigung];
      } else {
        besichtigungen = vorgang.besichtigungen.items.map((it) => (it.id === aktualisierteBesichtigung.id ? aktualisierteBesichtigung : it));
      }

      setzeVorgang({ ...vorgang, besichtigungen: { items: besichtigungen } });
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Besichtigung konnten nicht gespeichert werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }

  async function loescheBesichtigungsdaten(besichtigung: Besichtigung) {
    try {
      setLoading(true);

      await makeGraphqlQuery(mutations.loescheBesichtigung, {
        input: JSON.stringify({
          id: besichtigung.id
        })
      });

      const besichtigungsdaten = vorgang.besichtigungen.items.filter((it) => it.id !== besichtigung.id);

      setzeVorgang({ ...vorgang, besichtigungen: { items: besichtigungsdaten } });
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Besichtigung konnten nicht gelöscht werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      <Grid item xs={12} md={6}>
        <Auftraggeber
          vorgang={vorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          speichereVorgang={speichereVorgang}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fahrzeughalter
          vorgang={vorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          speichereVorgang={speichereVorgang}
          setzeVorgang={setzeVorgang}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </Grid>
      {vorgang.besichtigungen.items.map((besichtigung: Besichtigung, index: number) => {
        return (
          <Grid item xs={12} md={6} key={index}>
            <Besichtigungsdaten
              vorgang={vorgang}
              isLoading={isLoading}
              title={`Daten zur ${index + 1}. Besichtigung`}
              besichtigung={besichtigung}
              removeBesichtigung={async () => {
                await loescheBesichtigungsdaten(besichtigung);
              }}
              editBesichtigung={() => {
                setBesichtigung(besichtigung);
              }}
              createBesichtigung={() => {
                setBesichtigung({});
              }}
              dataTestId={`besichtigung-${index}`}
            />
          </Grid>
        );
      })}
      {vorgang.besichtigungen.items.length === 0 && (
        <Grid item xs={12} md={6}>
          <Besichtigungsdaten
            vorgang={vorgang}
            isLoading={isLoading}
            title={'Daten zur 1. Besichtigung'}
            besichtigung={{}}
            editBesichtigung={() => {
              setBesichtigung({});
            }}
            createBesichtigung={() => {
              setBesichtigung({});
            }}
          />
        </Grid>
      )}
      {besichtigung && (
        <Besichtigungdialog
          title={
            findIndex(besichtigung, vorgang.besichtigungen) !== -1
              ? `Daten zur ${findIndex(besichtigung, vorgang.besichtigungen) + 1}. Besichtigung`
              : 'Daten zur neuen Besichtigung'
          }
          vorgang={vorgang}
          isLoading={isLoading}
          setLoading={setLoading}
          aktualisiereBesichtigungsdaten={aktualisiereBesichtigungsdaten}
          besichtigung={besichtigung}
          onClose={() => setBesichtigung(null)}
        />
      )}
    </Grid>
  );
}

export function findIndex(besichtigung: Partial<Besichtigung>, besichtigungen: BesichtigungenConnection) {
  return besichtigungen.items.findIndex(({ id }) => id === besichtigung.id);
}
