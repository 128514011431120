import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { AKTION_KUNDENDATEN_ERFASSEN } from '../frontendConstants';
import { toDateTimeString_plain } from '../shared/dateTime';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { Besichtigung, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { SPACING_BETWEEN_BOXES } from './common/spacings';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly title: string;
  readonly removeBesichtigung?: () => void;
  readonly besichtigung: Partial<Besichtigung>;
  readonly editBesichtigung: () => void;
  readonly createBesichtigung: () => void;
  readonly dataTestId?: string;
};

export function Besichtigungsdaten({
  vorgang,
  isLoading,
  title,
  removeBesichtigung,
  besichtigung,
  editBesichtigung,
  createBesichtigung,
  dataTestId
}: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_KUNDENDATEN_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  let demontiertLabel = '';
  if (besichtigung.demontierterZustand === true) {
    demontiertLabel = 'Ja';
  } else if (besichtigung.demontierterZustand === false) {
    demontiertLabel = 'Nein';
  }

  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(SPACING_BETWEEN_BOXES)
      })}
      data-testid={dataTestId}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box pt={2}>
        <Grid container>
          <Grid item xs={6}>
            Adresse:
          </Grid>
          <Grid item xs={6} data-testid={'adresse'}>
            {besichtigung.strasse} {besichtigung.plz} {besichtigung.ort}
          </Grid>
          <Grid item xs={6}>
            Vermittler:
          </Grid>
          <Grid item xs={6} data-testid={'vermittler'}>
            {besichtigung?.vermittler?.vorname} {besichtigung?.vermittler?.nachname}
          </Grid>
          <Grid item xs={6}>
            Besichtigungstermin:
          </Grid>
          <Grid item xs={6} data-testid={'besichtigungstermin'}>
            {toDateTimeString_plain(besichtigung.besichtigungstermin)}
          </Grid>
          <Grid item xs={6}>
            demontierter Zustand:
          </Grid>
          <Grid item xs={6} data-testid={'demontiert'}>
            {demontiertLabel}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton disabled={isDisabled} onClick={editBesichtigung} data-testid="besichtigungsdaten-bearbeiten" size="large">
            <Tooltip title="Ändern">
              <EditIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled} onClick={createBesichtigung} data-testid="besichtigungsdaten-anlegen" size="large">
            <Tooltip title="Anlegen">
              <AddBoxIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled || !removeBesichtigung} onClick={removeBesichtigung} data-testid="besichtigungsdaten-loeschen" size="large">
            <Tooltip title="Löschen">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      </Box>
    </Paper>
  );
}
